.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
    background-image: url("../../images/404.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.notFound_block {
    position: absolute;
    bottom: 0;
    text-align: center;
}

.notFound_title {
    /*font-family: 'Bebas Neue', cursive;*/
    font-size: 24px;
    line-height: 150%;
    color: #fff;
}

.notFound_img {
    width: 100%;
}

.notFound_Link {
    color: #000;
    font-family: 'Bebas Neue', cursive;
    font-size: 40px;
}

.notFound_Link button {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    padding: 3.71% 13.4%;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    margin: 42px 0 44px;
}

@media (max-width: 425px) {
    .notFound {
        background-size: contain;
    }

    .notFound_block {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100vh;
    }

    .notFound_title {
        color: #000;
    }

    .notFound_Link button {
        border: 1px solid #000;
        color: #000;
    }
}