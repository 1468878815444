.menu {
    display: none;
}

    .menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateX(-101%);
        transition: .5s linear !important;
        display: block;
        overflow: hidden;
    }

/*  */
.menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: #fff;
    font-weight: normal;
    height: 100vh;
}

.burgerMenuLink {
    color: #ffffff;
    text-decoration: none;
    width: 80%;
    text-align: center;
    margin-bottom: 21px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
}

.burgerMenusubtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 50px 0;
}

.burgerMenu {
    display: flex;
    width: 86.5%;
    margin: 37px auto 0;
    justify-content: space-between;
}

.mobileView {
    display: flex;
    width: 86.5%;
    margin: 37px auto 0;
    align-items: center;
}

.burger_Logo {
    width: 43.4%;
    position: relative;
    z-index: 3;
    height: 16px;
}
/*	burger menu*/
.burgerBlack {
    position: relative;
    z-index: 3;
    width: 20%;
    height: 24px;
    top: 0;
    cursor: pointer;
}
.burgerBlack span {
    height: 3px;
    width: 29px;
    background: #000;
    position: absolute;
    transition: 1s;
    top: 31%;
}
.burgerBlack::before,
.burgerBlack::after {
    content: '';
    position: absolute;
    background: #000;
    width: 40px;
    height: 3px;
    transition: 1s;
    z-index: 4;
}
.burgerBlack::before {
    top: 0;
    left: 0;
    width: 29px;
}
.burgerBlack::after {
    bottom: 7px;
    left: 0;
    width: 29px;
}
.burgerBlack.active span {
    height: 3px;
    width: 17px;
    background: #fff;
    position: absolute;
    transition: 1s;
}
.burgerBlack.active::before,
.burgerBlack.active::after {
    content: '';
    position: absolute;
    background: #000;
    height: 3px;
    transition: 1s;
    z-index: 4;
}

.burgerBlack.active:before {
    transform: rotate(45deg);
    top: 0;
    left: 0;
    width: 32px;
    background: #000;
    transition: 1s;
}
.burgerBlack.active::after {
    transform: rotate(-45deg);
    bottom: 21px;
    width: 32px;
    left: 0;
    background: #000;
    transition: 1s;
}
.burgerBlack.active span {
    transform: translateX(45px) scale(0);
    transition: 1s;
}

.Menu_Link {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.1em;
    color: #1B2232;
    text-decoration: none;
}

.menuNav {
    margin-right: 5%;
    z-index: 22;
}

.menuNav li {
    margin-bottom: 18px;
    list-style: none;
}

.dropMenu_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20%;
}

.blur {
    width: 136.5vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    position: absolute;
}


@media  (max-width: 1280px) {
    .menu {
        transform: translateX(-104%);
    }
    .menuNav li {
        margin-bottom: 13px;
    }

    .menu-container {
        align-items: flex-start;
    }

    .menuNav {
        margin-left: 45px;
        width: 63%;
    }

    .Menu_Link {
        font-size: 19px;
    }

    .dropMenu_footer {
        width: 700px;
    }
}

@media  (max-width: 1173px) {
    .dropMenu_footer {
        width: 100%;
    }
}

    @media  (max-width: 1024px) {
    .dropMenu_footer {
        width: 550px;
    }
}

@media  (max-width: 955px) {
    .dropMenu_footer {
        width: 454px;
    }
}

@media (max-width: 786px) {
    .dropMenu_footer {
        width: 400px;
    }
}

@media (max-width: 639px) {
    .menuActive {
        width: 73%;
    }
}

@media (max-width: 686px) {
    .menuNav {
        width: 89%;
    }
    .dropMenu_footer {
        width: 100%;
    }
}

@media (max-width: 425px) {
    .menuActive {
        width: 102%;
    }

    .mobileViewIcon {
        margin-right: 10px;
    }

    .menu {
        height: 500px;
    }

    .Menu_Link {
        font-size: 17px;
        line-height: 21px;
    }

    .menuNav li {
        margin-bottom: 12px;
    }

    .menu-container {
        justify-content: center;
        height: 100vh;
    }

    .menuNav {
        margin-top: 25%;
        width: 90%;
    }

    .dropMenu_footer {
        width: 384px;
    }

    /*.dropMenu_footer  img{*/
    /*    width: 400px;*/
    /*}*/
}

@media (max-width: 375px) {
    .dropMenu_footer {
        width: 329px;
    }
}

@media (max-width: 320px) {
    .dropMenu_footer {
        width: 292px;
        margin-top: 12%;
    }

    .dropMenu_footer img {
        width: 40%;
    }

    .Menu_Link {
        line-height: 0;
    }

    .menuNav {
        margin-left: 30px;
    }
}
