@import url('vendor/fonts/fonts.css');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
* {
  padding: 0;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
